import React from 'react'

export default function NotFound() {
  return (
    <div className="not-found">
        <img src="https://media.giphy.com/media/VwoJkTfZAUBSU/giphy.gif" alt="404 gif" aria-hidden></img>
        {/* <p>You ended up on a route that doesn&#39;t exist... the sadness.</p> */}
        <p>
            Oh! Horror <span role="img" aria-label="shock face emoji">😱</span> 404 Error <span role="img" aria-label="shock face emoji">😱</span> <br></br>Please navigate through the links above!
        </p>
    </div>
  )
}
